import { useLocation } from '@remix-run/react'
import { useEffect } from 'react'

declare global {
  interface Window {
    gtag: (option: string, gaTrackingId: string, options: Record<string, unknown>) => void
  }
}

type Props = {
  environment: string
  gtmTrackingId: string | undefined
}

export default function useGoogleTagManager({ environment, gtmTrackingId }: Props) {
  const location = useLocation()

  useEffect(() => {
    if (environment !== 'production' || !gtmTrackingId) {
      return () => null
    }

    // Load the tag manager script
    const gtmScript = document.createElement('script')
    gtmScript.async = true
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmTrackingId}`

    // Initialize tag manager
    const gtmInit = document.createElement('script')
    gtmInit.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());`

    document.head.appendChild(gtmScript)
    document.head.appendChild(gtmInit)

    return () => {
      document.head.removeChild(gtmScript)
      document.head.removeChild(gtmInit)
    }
  }, [location, gtmTrackingId])

  // Capture a page view
  useEffect(() => {
    if (environment !== 'production' || !gtmTrackingId) {
      return
    }

    window.gtag('config', gtmTrackingId, {
      page_location: location.pathname
    })
  }, [location.pathname])
}
