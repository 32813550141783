declare global {
  interface Window {
    ENV: PublicEnvs
  }
}

export type PublicEnvs = {
  ENVIRONMENT: 'development' | 'staging' | 'production'
  MAPBOX_ACCESS_TOKEN: string
  GA_TAG_MANAGER_KEY: string
  ACTIVECAMPAIGN_ID: string
  SCHEDULE_BUILDER_FE_URL: string

  SENTRY_ENABLED: boolean
  SENTRY_TRACE_RATE: number
  SENTRY_REPLAY_RATE: number
  SENTRY_REPLAY_ERROR_RATE: number
}

export default function PublicEnvironmentVariables(props: PublicEnvs) {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(props)}`
      }}
    />
  )
}
