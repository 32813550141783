import { Dialog } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useLocation, useNavigate, useSearchParams } from '@remix-run/react'

export function BecomeAMemberModal() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  searchParams.delete('become-a-member')
  const returnTo = encodeURIComponent(`${location.pathname}?${searchParams.toString()}`)
  const loginUrl = `/login?returnTo=${returnTo}`

  function closeModal() {
    navigate('/')
  }

  return (
    <Dialog open as="div" className="overflow-y-aut fixed inset-0 z-10" onClose={closeModal}>
      <div className="modal modal-open modal-bottom sm:modal-middle">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <Dialog.Panel className="modal-box relative">
          {/*  eslint-disable-next-line prettier/prettier  */}
          <label className="btn-ghost btn-sm btn-circle btn absolute right-2 top-2" onClick={closeModal}>
            ✕
          </label>
          <div className="flex flex-col gap-4">
            <Dialog.Title as="h3" className="text-3xl font-bold text-gray-900">
              You&apos;ve reached the search limit, register to see your results!
            </Dialog.Title>
            <p>Register for free to continue searching and send multiple quote requests.</p>
            <div className="grid">
              <a href="/register" className="btn btn-primary">
                <span>Create my free account</span>
                <ChevronRightIcon className="h-4 w-4" />
              </a>
            </div>
            <div>
              Already have an account?{' '}
              <a className="link-hover link-secondary link" href={loginUrl}>
                Log in
              </a>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
