import { useEffect } from 'react'

type Props = {
  environment: string
  activeCampaignId: string | undefined
  userEmail: string | undefined
}

export default function useActiveCampaign({ environment, activeCampaignId, userEmail }: Props) {
  useEffect(() => {
    if (environment !== 'production' || !activeCampaignId) {
      return () => null
    }

    const activeCampaignScript = document.createElement('script')
    activeCampaignScript.async = true
    activeCampaignScript.innerHTML = `
      (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
      vgo('setAccount', '${activeCampaignId}');
      vgo('setTrackByDefault', true);
      vgo('setEmail', '${userEmail}');
      vgo('process');`

    document.head.appendChild(activeCampaignScript)

    return () => {
      document.head.removeChild(activeCampaignScript)
    }
  }, [userEmail])

  return null
}
